<template>
  <div>
    <Breadcrumb
      :home="{ icon: 'pi pi-home', to: '/admin' }"
      :model="BreadcrumbItems"
    />

    <Toolbar class="p-mb-4 mt-2">
      <template #left>
        اضافه جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/users/admins')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="userName" class="form-label">
            اسم الموظف
          </label>
          <input
            type="text"
            class="form-control"
            id="userName"
            v-model="body.name"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="email" class="form-label">
            البريد الالكتروني
          </label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="body.email"
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="password" class="form-label">
            كلمه المرور
          </label>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="body.password"
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="password" class="form-label">
            تأكيد كلمه المرور
          </label>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="body.confermedPassword"
          />
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3 text-center">
          <Button
            label="تعديل"
            icon="pi pi-pencil"
            class="p-ml-2 p-button-info"
            @click="update()"
            v-if="id"
          />
          <Button
            v-else
            label="اضافه جديد"
            icon="pi pi-plus"
            class="p-ml-2 p-button-success"
            @click="save()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      BreadcrumbItems: [
        {
          label: 'المديرين',
          to: '/admin/users/admins',
        },
        { label: 'اضافه' },
      ],
      body: {
        userName: null,
        email: null,
        password: null,
        phone: null,
        note: null,
        name: null,
        confermedPassword: null,
        rolesId: null,

        // branches: [],
      },
      id: null,
      rolesList: [],

      branchesList: [],
    };
  },
  methods: {
    save() {
      if (
        this.body.email &&
        this.body.password &&
        this.body.confermedPassword &&
        this.body.password == this.body.confermedPassword &&
        this.body.name

        // &&
        // this.body.branches.length > 0
      ) {
        this.$http.post(`users`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافه بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/users/admins');
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
    update() {
      if (
        this.body.email &&
        this.body.name

        // &&
        // this.body.branches.length > 0
      ) {
        this.$http.put(`users/${this.id}`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/users/admins');
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`users/${this.id}`).then(
        (res) => {
          this.body = res.data.data;
          this.body.password = null;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>


